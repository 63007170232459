import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Device } from '../interfaces/device';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  cart$ = new BehaviorSubject<Device[]>([]);

  constructor() {}

  addToCart(product: Device) {
    this.cart$.next([product, ...this.cart$.value]);
  }
}
