<div class="wrapper">
  <div fxLayout="column" fxFill fxLayoutGap.lt-sm="16px">
    <div fxLayout="row">
      <div fxLayout="column" fxFlex>
        <div class="company__text">SWATCHPAY!</div>
        <div class="product__text">{{ product.name }}</div>
      </div>
    </div>

    <div>
      <div>
        <span class="product__price">{{
          product.price | currency: 'EUR':'symbol':'1.0-0'
        }}</span>
        <span class="product__price__description"> (incl. tax)</span>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div fxFlex class="description__wrapper">
      <div>
        <span class="mat-body-2">SMALL PRODUCT DESCRIPTION:</span>
        <span class="product__small_description">
          {{ product.description }}</span
        >
      </div>
      <mat-divider></mat-divider>
    </div>

    <div fxLayout fxLayoutGap="8px">
      <button
        mat-stroked-button
        class="shopping__button"
        (click)="selectProduct(product)"
        fxFlex
      >
        <div class="shopping__button__text">ADD TO CART</div>
      </button>
      <button mat-stroked-button class="shopping__button">
        <img
          src="./assets/icons/favorite.svg"
          alt="favorite"
          style="height: 24px;"
        />
      </button>
    </div>
  </div>
</div>
