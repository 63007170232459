<div fxLayout="row" class="primary__header">
  <div fxLayoutAlign="start center" fxHide.lt-sm="true" fxFlex.gt-sm="450px">
    <a href="./">
      <img src="./assets/logo.svg" alt="logo" class="logo" />
    </a>
  </div>
  <div fxFlex class="header__actions">
    <div
      fxLayout="row"
      fxLayoutGap="16px"
      style="width: 500px; margin: 0 auto;"
    >
      <div>
        <a
          mat-button
          fxFlex
          color="primary"
          routerLink="home"
          class="active__link"
          >SHOP</a
        >
      </div>
      <div>
        <a
          mat-button
          fxFlex
          color="primary"
          routerLink="card"
          class="active__link"
          >Iframe</a
        >
      </div>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutGap="24px"
    fxLayoutGap.lt-sm="16px"
    fxLayoutAlign="end center"
  >
    <div *ngIf="devices$ | async as devices">
      <a
        mat-icon-button
        matBadgeColor="primary"
        [matBadge]="devices?.length"
        [matBadgeHidden]="devices?.length === 0"
        [matMenuTriggerFor]="menu"
      >
        <img src="./assets/icons/shopping_bag.svg" alt="shopping bag" />
      </a>

      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          (click)="buyProducts(devices)"
          *ngIf="devices.length; else emptyBasket"
        >
          <div class="buy__button__text">Buy it now!</div>
        </button>
        <ng-template #emptyBasket>
          <div class="error">Your basket is empty.</div>
        </ng-template>
      </mat-menu>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="end stretch">
      <button
        mat-stroked-button
        [matMenuTriggerFor]="langMenu"
        color="primary"
        class="lang__button"
      >
        <span>{{ currLang }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #langMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let lang of languages"
          (click)="changeLanguage(lang)"
        >
          {{ lang }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>
