import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ElementRef,
} from '@angular/core';
import { Device } from '../interfaces/device';
import { of, Observable } from 'rxjs';
import { ShoppingCartService } from '../core/shopping-cart.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes,
} from '@angular/animations';

const products: Device[] = [
  {
    deviceId: '1',
    deviceModel: 'SVIP101',
    name: 'PASTEL PAY!',
    price: 80,
    description: `Expliquibus ali- bus quibus eum ex eos ipsam
                    et pora conet liaerum, volorep ereium facere,
                    qui tetur aut lit eatia doluptatet qui optas
                    et harum nul- lorectota dit aut inustissunt
                    pla nat. Cupta sum volut pa velicim quis et.`,
    imageUrl:
      'https://static.swatch.com/images/product/SVIP101-1300/sa000/SVIP101-1300_sa000_sr9.jpg',
  },
  {
    deviceId: '2',
    deviceModel: 'SVIB105-5300',
    name: 'BLACKBACK PAY!',
    price: 100,
    description: `Expliquibus ali- bus quibus eum ex eos ipsam
                    et pora conet liaerum, volorep ereium facere,
                    qui tetur aut lit eatia doluptatet qui optas
                    et harum nul- lorectota dit aut inustissunt
                    pla nat. Cupta sum volut pa velicim quis et.`,
    imageUrl:
      'https://static.swatch.com/images/product/SVIB105-1300/sa000/SVIB105-1300_sa000_sr9.jpg',
  },

  {
    deviceId: '3',
    deviceModel: 'SVIM100',
    name: 'GRAPHITE PAY!',
    price: 120,
    description: `Expliquibus ali- bus quibus eum ex eos ipsam
                    et pora conet liaerum, volorep ereium facere,
                    qui tetur aut lit eatia doluptatet qui optas
                    et harum nul- lorectota dit aut inustissunt
                    pla nat. Cupta sum volut pa velicim quis et.`,
    imageUrl:
      'https://static.swatch.com/images/product/SVIM100-5300/sa000/SVIM100-5300_sa000_sr9.jpg',
  },
];

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('prevNextProduct', [
      transition('* => prev', [
        animate(
          '300ms',
          keyframes([
            style({ transform: 'translateX(40px)', offset: 0 }),
            style({
              transform: 'translateX(0)',
              offset: 1,
            }),
          ])
        ),
      ]),
      transition('* => first', [
        animate(
          '300ms',
          keyframes([
            style({ transform: 'translateX(40px)', offset: 0 }),
            style({
              transform: 'translateX(0)',
              offset: 1,
            }),
          ])
        ),
      ]),
      transition('* => next', [
        animate(
          '300ms',
          keyframes([
            style({ transform: 'translateX(-40px)', offset: 0 }),
            style({
              transform: 'translateX(0)',
              offset: 1,
            }),
          ])
        ),
      ]),
      transition('* => last', [
        animate(
          '300ms',
          keyframes([
            style({ transform: 'translateX(-40px)', offset: 0 }),
            style({
              transform: 'translateX(0)',
              offset: 1,
            }),
          ])
        ),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  products$ = of(products);
  selectedProducts$: Observable<Device[]>;
  activeProduct = 0;
  currentState = 'final';
  @ViewChildren('products') productsDivs: QueryList<ElementRef>;

  constructor(private shoppingBagService: ShoppingCartService) {}

  ngOnInit(): void {
    this.selectedProducts$ = this.shoppingBagService.cart$;
  }

  hasNextProduct(currIndex: number, productsLength: number): boolean {
    const index = currIndex + 1;
    return productsLength - index > 0 ? true : false;
  }

  hasPrevProduct(currIndex: number): boolean {
    return currIndex !== 0;
  }

  showNextProduct() {
    this.activeProduct += 1;
    this.currentState = this.currentState === 'next' ? 'last' : 'next';
  }

  showPrevProduct() {
    this.activeProduct -= 1;
    this.currentState = this.currentState === 'prev' ? 'first' : 'prev';
  }
}
