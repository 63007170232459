<div
  *ngIf="products$ | async as products"
  class="wrapper"
  fxLayout="row"
  fxLayoutAlign="start center"
>
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap.lt-sm="16px"
    *ngIf="products[activeProduct] as product"
    fxFlex
  >
    <div
      fxFlexOffset="100px"
      class="watch__image__wrapper"
      fxLayout
      fxLayoutAlign="center"
    >
      <img
        src="./assets/images/products/{{ product.imageUrl.split('/').pop() }}"
        alt="pastel watch"
        [@prevNextProduct]="currentState"
      />
    </div>

    <div fxFlex class="watch__description__wrapper">
      <div fxFlex="50px" fxFlexAlign="center" style="margin-left: -50px;">
        <button
          mat-icon-button
          *ngIf="hasPrevProduct(activeProduct)"
          (click)="showPrevProduct()"
        >
          <mat-icon
            color="primary"
            title="previous product"
            class="product__change__icon"
            >keyboard_arrow_left</mat-icon
          >
        </button>
      </div>
      <ws-product-short-details [product]="product"></ws-product-short-details>
      <div fxFlex="50px" fxFlexAlign="center">
        <button
          mat-icon-button
          *ngIf="hasNextProduct(activeProduct, products.length)"
          (click)="showNextProduct()"
        >
          <mat-icon
            color="primary"
            title="next product"
            class="product__change__icon"
            >keyboard_arrow_right</mat-icon
          >
        </button>
      </div>
    </div>
  </div>
</div>
