import { Injectable, Inject } from '@angular/core';
import { OrderRequest, OrderSuccess } from '../interfaces/order';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private http: HttpClient,
    @Inject('backendUrl') private readonly backendUrl: string
  ) {}

  // Create an order
  create(request: OrderRequest, token: string): Observable<OrderSuccess> {
    return this.http
      .post<OrderSuccess>(`${this.backendUrl}/order`, request, {
        headers: new HttpHeaders().append('Authorization', `bearer ${token}`),
      })
      .pipe(retry(1));
  }
}
