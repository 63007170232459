<iframe
  #iframe
  allow="camera;"
  frameborder="0"
  scrolling="no"
  src="https://swatchpay-ecomm.wearonize.com/eligibility-check"
  width="100%"
  wsResizer
></iframe>
<button
  mat-raised-button
  color="primary"
  [disabled]="isDisabledButton"
  (click)="submit()"
>
  Submit
</button>
