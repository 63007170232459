import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    @Inject('authUrl') private readonly authUrl: string
  ) {}

  getToken() {
    const AUTH_TOKEN_PRODUCTION = 'Bearer c3dhdGNocGF5Ok1qbldtdzBwNXRsbzhIVGI=';
    const AUTH_TOKEN_DEV = 'Bearer c3dhdGNocGF5OnRMQlZ4OTdTcmtwSWtualdra3lw';

    const token = environment.production
      ? AUTH_TOKEN_PRODUCTION
      : AUTH_TOKEN_DEV;
    return this.http.post(
      `${this.authUrl}/token`,
      'grant_type=client_credentials',
      {
        headers: new HttpHeaders()
          .append('Authorization', token)
          .append('Content-Type', 'application/x-www-form-urlencoded'),
      }
    );
  }
}
