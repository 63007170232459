import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[wsResizer]',
})
export class ResizerDirective {
  constructor(private el: ElementRef) {}

  @HostListener('window:resize', ['$event.target'])
  onResize() {
    this.resize();
  }

  @HostBinding('style.height.px')
  elHeight: number;

  private resize(): void {
    const width = this.el.nativeElement.clientWidth;
    if (width > 400) {
      this.el.nativeElement.classList.remove('mobile-iframe');
      this.el.nativeElement.classList.add('desktop-iframe');
    } else {
      this.el.nativeElement.classList.remove('desktop-iframe');
      this.el.nativeElement.classList.add('mobile-iframe');
    }
  }
}
