import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Device } from '../../interfaces/device';
import { ShoppingCartService } from 'src/app/core/shopping-cart.service';

@Component({
  selector: 'ws-product-short-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsComponent implements OnInit {
  @Input() product: Device;

  constructor(private shoppingCartService: ShoppingCartService) {}

  ngOnInit(): void {}

  selectProduct(product: Device) {
    this.shoppingCartService.addToCart(product);
  }
}
