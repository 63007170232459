import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ws-card-number',
  templateUrl: './card-number.component.html',
  styleUrls: ['./card-number.component.scss'],
})
export class CardNumberComponent implements OnInit, AfterViewInit {
  status = 'ELIGIBILITY_CHECK_RESET';
  @ViewChild('iframe') iframe: ElementRef;

  get isDisabledButton() {
    return ![
      'ELIGIBILITY_CHECK_READY',
      'ELIGIBILITY_CHECK_SUCCESS',
      'ELIGIBILITY_CHECK_ERROR',
    ].includes(this.status);
  }

  ngOnInit(): void {
    window.addEventListener('message', event => {
      const data = event.data;

      console.log(data.status);
      if (data.status === this.status) {
        return;
      }

      switch (data.status) {
        case 'ELIGIBILITY_CHECK_READY':
        case 'ELIGIBILITY_CHECK_RESET':
          this.status = data.status;
          break;
        case 'CAMERA_OPENED':
          this.status = data.status;
          console.log(data.status);
          // this.iframe.nativeElement.classList.remove('desktop-iframe');
          // this.iframe.nativeElement.classList.remove('mobile-iframe');
          this.iframe.nativeElement.classList.add('fullscreen-iframe');
          break;
        case 'CAMERA_CLOSED':
          // this.status = data.status;
          this.iframe.nativeElement.classList.remove('fullscreen-iframe');
          break;
      }
    });
  }

  ngAfterViewInit(): void {
    this.setIframeHeight();
  }

  submit() {
    console.log(this.iframe.nativeElement);
    const iframe = this.iframe.nativeElement.contentWindow;

    iframe.postMessage({ status: 'ELIGIBILITY_CHECK_PENDING' }, '*');
  }

  private setIframeHeight(): void {
    const width = this.iframe.nativeElement.clientWidth;
    if (width > 400) {
      this.iframe.nativeElement.classList.add('desktop-iframe');
    } else {
      this.iframe.nativeElement.classList.add('mobile-iframe');
    }
  }
}
