import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OrderRequest } from 'src/app/interfaces/order';
import { environment } from 'src/environments/environment';
import { Device } from '../../interfaces/device';
import { AuthService } from '../auth.service';
import { OrderService } from '../order.service';
import { ShoppingCartService } from '../shopping-cart.service';

@Component({
  selector: 'ws-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  devices$: Observable<Device[]>;
  languages = [
    'EN',
    'DE',
    'ES',
    'FR',
    'IT',
    'NL',
    'PL',
    'PT',
    'UK',
    'RU',
    'TH',
    'AR',
  ];
  currLang = 'EN';

  constructor(
    private shoppingCartService: ShoppingCartService,
    private authService: AuthService,
    private orderService: OrderService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.devices$ = this.shoppingCartService.cart$.asObservable();
  }

  buyProducts(devices: Device[]) {
    // const SHOP_RETURN_URL_PROD = 'https://swatchpay-ecomm-shop.wearonize.com';
    const SHOP_RETURN_URL_DEV = 'https://shop.wearonize.dev';

    const returnUrl = SHOP_RETURN_URL_DEV;

    if (devices.length) {
      const orderRequest: OrderRequest = {
        // Generate random string
        orderType: 'SINGLE_LINE',
        orderId:
          'TEST-' +
          Math.random()
            .toString(36)
            .substring(7),
        updateStatusUrl:
          'https://preprod-crm.swatch.com/en/shop-connector/swatch-pay/update-tokenization-status',
        returnUrl,
        consumerLanguage: this.currLang,
        devices,
      };

      this.authService
        .getToken()
        .pipe(
          switchMap((token: { accessToken: string }) => {
            return this.orderService.create(orderRequest, token.accessToken);
          })
        )
        .subscribe(value => {
          this.document.location.href = value.tokenizationUrl;
        });
    }
  }

  changeLanguage(lang: string) {
    this.currLang = lang;
  }
}
