// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAXzL7Uj51fzl2ajkA_T4INGJMEkIpPb4M',
    authDomain: 'swatchpay-ecomm-dev.firebaseapp.com',
    databaseURL: 'https://swatchpay-ecomm-dev.firebaseio.com',
    projectId: 'swatchpay-ecomm-dev',
    storageBucket: 'swatchpay-ecomm-dev.appspot.com',
    messagingSenderId: '280501321559',
    appId: '1:280501321559:web:3074766cac85ec90e08812',
    measurementId: 'G-1DXZG2D0DQ',
  },
  // host: 'localhost:4200',
  host: 'shop.wearonize.dev',
  backendUrl: 'https://orderservice-dxo6fgfdfa-ew.a.run.app/v1',
  authUrl: 'https://authservice-dxo6fgfdfa-ew.a.run.app/v1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
