import { NgModule, LOCALE_ID, Inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from 'src/app/core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { registerLocaleData, DOCUMENT } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { CardNumberComponent } from './card-number/card-number.component';
import { MatButtonModule } from '@angular/material/button';
import { ResizerDirective } from './card-number/resizer.directive';

registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent, CardNumberComponent, ResizerDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HomeModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: 'backendUrl', useValue: environment.backendUrl },
    { provide: 'authUrl', useValue: environment.authUrl },
    { provide: 'host', useValue: environment.host },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
