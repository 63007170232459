import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ws-root',
  templateUrl: './app.component.html',
  styles: [
    `
      .wrapper {
        padding: 2em;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0px 280px,
            rgba(255, 255, 255, 0.5) 280px
          ),
          url(./assets/background.svg);
        background-size: cover;
        box-sizing: border-box;
        min-height: 100vh;
        overflow: hidden;
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject('host') private host: string
  ) {}

  ngOnInit(): void {
    const currHost = this.document.location.host;
    // if (currHost !== this.host) {
    //   this.document.location.href = this.document.location.href.replace(
    //     currHost,
    //     this.host
    //   );
    // }
  }
}
